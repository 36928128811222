<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <!-- Start Page -->
  <div id="page" class="">
    <!-- Footer -->
    <Footer logged />
    <!--End Footer -->

    <!-- Page Content -->
    <div class="page-content pb-0">
      <div data-card-height="cover-full" class="card mb-0">
        <div class="card-center">
          <div class="text-center">
            <h1 class="font-40 color-highlight">PasJob</h1>
            <p class="font-600 mb-1 font-14">Ubah Sandi</p>
          </div>

          <div class="content px-4">
            <div v-if="errors" class="mb-4">
              <p class="color-red-dark">
                <i class="fa fa-info color-red-dark"></i>
                {{ errors }}
              </p>
            </div>
            <form @submit.prevent="submitForm">
              <div
                class="input-style no-borders has-icon validate-field mb-4"
                :class="{ 'input-style-active': password1 }"
              >
                <i class="fa fa-lock"></i>
                <input
                  type="password"
                  class="form-control validate-name"
                  id="form1a"
                  placeholder="kata sandi"
                  v-model="password1"
                  required
                />
                <label for="form1a">Kata Sandi</label>
                <i class="fa fa-times disabled invalid color-red-dark"></i>
                <i class="fa fa-check disabled valid color-green-dark"></i>
                <em>(required)</em>
              </div>

              <div
                class="input-style no-borders has-icon validate-field mb-4"
                :class="{ 'input-style-active': password2 }"
              >
                <i class="fa fa-lock"></i>
                <input
                  type="password"
                  class="form-control validate-password"
                  id="form1ab"
                  placeholder="Tulis ulang kata sandi"
                  v-model="password2"
                  required
                  autocomplete="section-blue shipping address-level2"
                />
                <label for="form1ab">Kata sandi 2</label>
                <i class="fa fa-times disabled invalid color-red-dark"></i>
                <i class="fa fa-check disabled valid color-green-dark"></i>
                <em>(required)</em>
              </div>

              <button
                class="
                  btn btn-center-l btn-l
                  font-600 font-13
                  gradient-highlight
                  mt-4
                  rounded-s
                "
              >
                Ubah Sandi
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- End Page Content-->
    <!-- reset modals -->
    <div
      id="reset-password"
      class="menu menu-box-modal rounded-m"
      data-menu-height="220"
      data-menu-width="350"
    >
      <div class="menu-title">
        <p class="color-red-dark">
          <i class="fa fa-info color-red-dark"></i> Token tidak valid
        </p>
        <h1 class="font-24">Kirim ulang reset password</h1>
      </div>

      <div class="content mt-1">
        <div
          class="input-style no-borders has-icon validate-field mb-4"
          :class="{ 'input-style-active': email }"
        >
          <i class="fa fa-user"></i>
          <input
            type="email"
            class="form-control validate-name"
            id="form1email"
            placeholder="Email"
            v-model="email"
          />
          <label for="form1email">Email</label>
          <i class="fa fa-times disabled invalid color-red-dark"></i>
          <i class="fa fa-check disabled valid color-green-dark"></i>
          <em>(required)</em>
        </div>
        <a
          @click="sendEmail"
          href="#"
          class="
            btn btn-full btn-m
            shadow-l
            rounded-s
            bg-highlight
            font-600
            mt-4
          "
          >Kirim</a
        >
      </div>
    </div>
    <!--Success Box-->
    <div
      id="success"
      class="menu menu-box-modal bg-highlight rounded-m"
      data-menu-height="330"
      data-menu-width="350"
    >
      <h1 class="text-center mt-4">
        <i
          class="
            fa fa-3x fa-check-circle
            scale-box
            color-white
            shadow-xl
            rounded-circle
          "
        ></i>
      </h1>
      <h1 class="text-center mt-3 font-700 color-white">Berhasil Di Ubah</h1>
      <p class="boxed-text-l color-white opacity-90">
        Kata sandi anda berhasil di ganti<br />
        Silahkan login kembali
      </p>
      <router-link
        :to="{ name: 'SignIn' }"
        class="
          btn btn-m btn-center-m
          button-s
          shadow-l
          rounded-s
          text-uppercase
          font-600
          bg-white
          color-black
          mb-1
        "
        >Ke Halaman Login</router-link
      >
    </div>
    <!--End Success Box-->

    <!--End reset modals -->
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import { init_template, menuOpen, menuClose } from "@/components/menu/index.js";
/*import { preloader, load } from "@/components/script/custom.js";*/

import Footer from "@/components/Footer.vue";

export default {
  name: "NewPassword",
  data() {
    return {
      password1: "",
      password2: "",
      email: "",
      errors: "",
      isLoading: true,
      tokenValid: false,
    };
  },
  mounted() {
    init_template();
    this.tokenValid = this.$route.query.token_valid;
    if (!this.tokenValid) {
      this.menuOpen("reset-password");
    }
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
  },
  components: {
    Footer,
  },
  methods: {
    // function close for menu
    menuClose,
    menuOpen,
    validateInput() {
      this.errors = "";
      if (this.password1 === "") {
        this.errors = "Kata sandi tidak boleh kosong";
      }
      if (this.password1 !== this.password2) {
        this.errors = "Kata sandi tidak sama";
      }
      if (this.password1.length <= 8) {
        this.errors = "Kata sandi tidak boleh kurang dari 8";
      }
    },
    async sendEmail() {
      if (this.email) {
        console.log("change password");
        const data = {
          email: this.email,
          redirect_url: axios.defaults.baseURL + "/new-password",
        };
        await axios
          .post("/api/v1/auth/request-reset-email", data)
          .then((response) => {
            if (response.data.success) {
              this.$router.push({ name: "SignIn" });
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(JSON.stringify(error.response.data));
            } else if (error.message) {
              console.log(JSON.stringify(error));
            }
          });
      }
    },
    async submitForm() {
      console.log(this.password1.length);
      this.validateInput();
      if (!this.errors.length) {
        const formData = {
          password: this.password1,
          token: this.$route.query.token,
          uidb64: this.$route.query.uidb64,
        };

        await axios
          .patch("/api/v1/auth/password-reset-complete/", formData)
          .then((response) => {
            console.log(response.data);
            if (response.data.success) {
              this.menuOpen("success");
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(JSON.stringify(error.response.data));
            } else if (error.message) {
              console.log(JSON.stringify(error));
            }
          });
      }
    },
  },
};
</script>
